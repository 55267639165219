import { FC, useEffect, useRef, useState } from 'react'
import styles from './EasilyCooling.module.scss'
import { EasilyCoolingProps } from './EasilyCooling.types'
import { VideoGif } from 'components/basic/VideoGif'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { Type } from 'components/Type'
import { FullScreenModal } from 'components/Phantom/_sections/ThisIsPod4Ultra'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'

export const EasilyCoolingConnected = observer((props) => {
	const { settingsStore } = useRootStore()

	return (
		<EasilyCooling
			{...props}
			metric={settingsStore.metricRegion}
		/>
	)
})

export const EasilyCooling: FC<EasilyCoolingProps> = (props) => {
	const {
		eyebrow = 'Fits any bed',
		header = 'Easily add cooling to any mattress',
		subheader = 'The Pod Cover fits seamlessly over any mattress like a fitted-sheet, turning your bed into a high-tech sleep system.',
		video = false,
	} = props

	const coverRef = useRef<HTMLVideoElement>(null)
	const headerRef = useRef<HTMLDivElement>(null)

	const [videoVisible, setVideoVisible] = useState(false)

	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		if (videoRef.current) {
			if (videoVisible) {
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
	}, [videoVisible])

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger)

		ScrollTrigger.create({
			trigger: coverRef.current,
			start: '20% bottom',
			scrub: 1,
			onEnter: () => {
				if (!coverRef.current) return
				coverRef.current.currentTime = 0
				coverRef.current.play()

				// gsap.to(headerRef.current, {
				// 	y: 0,
				// 	opacity: 1,
				// 	duration: 1,
				// })
			},
			// onLeaveBack: () => {
			// 	gsap.to(headerRef.current, {
			// 		y: 100,
			// 		opacity: 0,
			// 		duration: 1,
			// 	})
			// },
		})
	}, [])

	return (
		<section
			className={styles.container}
			id={'easily-cooling'}
		>
			<div
				className={styles.header_container}
				ref={headerRef}
			>
				<header>
					<Type.Eyebrow
						className={styles.eyebrow}
						animateOnScroll
					>
						{eyebrow}
					</Type.Eyebrow>
					<Type.Headline2 animateOnScroll>{header}</Type.Headline2>
					<Type.Body1
						className={styles.subheader}
						animateOnScroll
					>
						{subheader}
					</Type.Body1>
					{props.video && (
						<Button.Dark
							id={'easily-cooling-video-cta'}
							onClick={() => setVideoVisible(true)}
							className={styles.video_button}
						>
							<Icon
								name={'PlayDark'}
								color={'white'}
								size={24}
							/>
							Watch the video
						</Button.Dark>
					)}
				</header>
			</div>

			<VideoGif
				src={'https://d115sb6i1ixllw.cloudfront.net/assets/prospecting_c_cover_drop-edit2.mp4'}
				ref={coverRef}
				loop={false}
				autoPlay={false}
			/>

			<FullScreenModal
				closeAction={() => setVideoVisible(false)}
				visible={videoVisible}
				aspectRatio={'16/9'}
			>
				<div className={styles.modal_video_container}>
					<video
						ref={videoRef}
						src={
							props.metric ? 'https://d115sb6i1ixllw.cloudfront.net/assets/pod_4_launch_metric_subtitles.mp4' : 'https://d115sb6i1ixllw.cloudfront.net/assets/Pod%204%20launch%20with%20subtitles.mp4'
						}
						preload={'none'}
						autoPlay={false}
						loop={false}
						controls
					/>
				</div>
			</FullScreenModal>
		</section>
	)
}
