import { FC, useRef } from 'react'
import styles from './PersonalizedSleep.module.scss'
import { PersonalizedSleepItemProps, PersonalizedSleepProps } from './PersonalizedSleep.types'
import { Img } from 'components/basic/Img'
import { Button } from 'components/Phantom/Button'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

export const PersonalizedSleep: FC<PersonalizedSleepProps> = (props) => {
	const { items = getDefaultItems(), theme = 'dark' } = props
	return (
		<div
			className={styles.container}
			data-theme={theme}
		>
			<header>
				<Type.Eyebrow
					className={styles.eyebrow}
					animateOnScroll
				>
					Eight Sleep App
				</Type.Eyebrow>
				<Type.Headline2 animateOnScroll>Personalized sleep, powered by your data</Type.Headline2>
			</header>
			<ul className={styles.items}>
				{items.map((item) => (
					<PersonalizedSleepItem
						key={item.title}
						{...item}
					/>
				))}
			</ul>
		</div>
	)
}

const PersonalizedSleepItem: FC<PersonalizedSleepItemProps> = (props) => {
	const ref = useRef<HTMLLIElement>(null)
	useAnimateInOnScroll(ref)

	return (
		<li
			className={styles.item}
			ref={ref}
		>
			<div className={styles.image_container}>
				<Img
					src={props.image.src}
					alt={props.image.alt}
				/>
			</div>
			<div className={styles.content_container}>
				<Type.Eyebrow className={styles.eyebrow}>{props.supertitle}</Type.Eyebrow>
				<Type.Headline3 className={styles.title}>{props.title}</Type.Headline3>
				<Type.Body1 className={styles.description}>{props.description}</Type.Body1>
				<Type.Body2 className={styles.disclaimer}>{props.disclaimer}</Type.Body2>
				<Button.TextLink
					id={'personalized-sleep-cta'}
					href={props.cta.href}
				>
					{props.cta.text}
				</Button.TextLink>
			</div>
		</li>
	)
}

const getDefaultItems = () => [
	{
		supertitle: 'Autopilot',
		title: 'Tracks your sleep to help you get more rest',
		description: 'Autopilot sends your sleep and health data to the app. Your metrics are summarized into a daily report to help you stay on top of your overall health.',
		cta: {
			text: 'Learn more',
			href: '/product/pod-cover',
		},
		image: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/prospecting_c/tracking.png',
			alt: 'A man looking at his phone. There is an inset image showing Increased deep sleep up 20% and Decreased deep sleep down 6bpm.',
		},
	},
	{
		supertitle: 'The Base',
		title: 'Detects and mitigates snoring',
		description: 'When snoring is detected, Autopilot automatically elevates your head to improve breathing*. Autopilot has been clinically proven to reduce snoring by up to 45%.',
		disclaimer: '*Available with Pod 4 Ultra only',
		cta: {
			text: 'Learn more',
			href: '/product/pod-cover',
		},
		image: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/prospecting_c/snoring.png',
			alt: 'Personalized Sleep',
		},
	},
]
