import { FC, useRef } from 'react'
import styles from './Hero.module.scss'
import { HeroProps } from './Hero.types'
import { BenefitProps } from 'components/_utils/utilityTypes'
import { Icon } from 'components/Phantom/Icon'
import { Button } from 'components/Phantom/Button'
import { Img } from 'components/basic/Img'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

export const HeroConnected = observer(() => {
	const { settingsStore } = useRootStore()
	return <Hero metric={settingsStore.metricRegion} />
})

export const CompareHeroConnected = observer(() => {
	const { settingsStore } = useRootStore()
	return (
		<Hero
			metric={settingsStore.metricRegion}
			header={'Clinically-proven better sleep. No one else does that.'}
			subheader={"Don't settle for just any bed cooling system."}
			eyebrow={'The Pod'}
		/>
	)
})

export const Hero: FC<HeroProps> = (props) => {
	const { benefits = getDefaultBenefits(props.metric), header = 'Cooler sleep, solved', subheader = '72 million nights of sleep already improved.', eyebrow } = props

	const ctaContainerRef = useRef<HTMLDivElement>(null)
	useAnimateInOnScroll(ctaContainerRef)

	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<header>
					<Type.Eyebrow
						className={styles.eyebrow}
						animateOnScroll
					>
						{eyebrow}
					</Type.Eyebrow>
					<Type.Headline1 animateOnScroll>{header}</Type.Headline1>
					<Type.Headchapter
						className={styles.subheader}
						animateOnScroll
					>
						{subheader}
					</Type.Headchapter>
				</header>
				<ul className={styles.benefits}>
					{benefits.map((benefit, i) => (
						<BenefitCard
							{...benefit}
							index={i}
						/>
					))}
				</ul>

				<div
					className={styles.cta_container}
					ref={ctaContainerRef}
				>
					<Button.Dark
						id={'prospecting-hero-shop'}
						href={'/product/pod-cover'}
					>
						Shop now
					</Button.Dark>
				</div>
				<Type.Body2
					className={styles.disclaimer}
					animateOnScroll
				>
					30-day risk free trial
				</Type.Body2>
			</div>

			<Img
				src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/prospecting_c/Hub+on+Ice+Header+Sky.png'}
				alt={''}
				className={styles.hero_image}
				objectFit={'cover'}
				dprHeight={1500}
				sources={[{ mediaQuery: '(max-width: 1023px)', src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/prospecting_c/hero_mobile.jpg' }]}
			/>
		</section>
	)
}

const getDefaultBenefits = (metric: boolean): BenefitProps[] => [
	{
		icon: 'BedLight',
		text: 'Fits any mattress',
	},
	{
		icon: 'CoolDark',
		text: `Cools to ${metric ? '12°C' : '55°F'}`,
	},
	{
		icon: 'TemperatureDark',
		text: 'Automated temperature',
	},
	{
		icon: 'SnoringLight',
		text: 'Reduces snoring',
	},
]

const BenefitCard: FC<BenefitProps & { index: number }> = (props) => {
	const { icon, text, index } = props
	const ref = useRef<HTMLLIElement>(null)
	useAnimateInOnScroll(ref, { delay: index * 0.1 })

	return (
		<li
			className={styles.benefit}
			ref={ref}
		>
			<Icon
				name={icon}
				color={'white'}
				size={24}
			/>
			<div className={styles.benefit_text}>
				<Type.Body2>{text}</Type.Body2>
			</div>
		</li>
	)
}
